const links = {
    "links": [
      {
        "link": "/",
        "label": "Home"
      },
      {
        "link": "/gst-calculator",
        "label": "GST Calculator"
      },
      {
        "link": "/learn",
        "label": "Learn"
      },
      {
        "link": "/community",
        "label": "Community"
      }
    ]
  }

  export default links;